import { dynamicImport, insertIf } from '@core/helpers'
import { ROUTES } from '@router'

import {
  ALLOWED_CONTEXTS,
  SAV_PLATFORM_CONTEXTS,
  SAV_PLATFORM_ROLES,
} from './Platform/constants'

export default ({ config }) => {
  return [
    // Customer request - Merchant SAV Platform.
    {
      name: ROUTES.SAV_PLATFORM.HOME,
      path: '/bo_merchant/customer-request',
      component: () => {
        const isSSRSwitchedOff = config.KILL_CUSTOMER_REQUESTS_SSR_FOR_SELLER

        return dynamicImport(
          import(
            isSSRSwitchedOff
              ? '@sav-platform/ConnectedSAVPlatformClientOnly'
              : '@sav-platform/ConnectedSAVPlatform'
          ),
        )
      },
      meta: {
        auth: {
          required: true,
          // TODO: Use route names instead
          redirection: ['/bo_merchant/'],
        },
        merchant: {
          required: true,
        },
      },
      props: {
        role: SAV_PLATFORM_ROLES.merchant,
      },
      redirect: {
        name: ROUTES.SAV_PLATFORM.CUSTOMER_REQUESTS.PENDING,
        params: {
          context: SAV_PLATFORM_CONTEXTS.pending,
        },
      },
      children: [
        {
          name: ROUTES.SAV_PLATFORM.INVOICE_REQUESTS,
          path: 'invoice-requests',
          components: {
            rightPanel: () =>
              dynamicImport(
                import('@sav-platform/pages/ConnectedInvoiceRequests.vue'),
              ),
          },
        },
        {
          // TODO Clean context on ASP routes SAX-392
          name: ROUTES.SAV_PLATFORM.CUSTOMER_REQUESTS.PENDING,
          path: ':context(pending)/:customerRequestId([0-9]+)?/:claimId([0-9]+)?',
          meta: {
            scrollToSavedPosition: false,
          },
          components: {
            rightPanel: () =>
              dynamicImport(
                import('@sav-platform/pages/ConnectedCustomerRequests.vue'),
              ),
          },
        },
        {
          name: ROUTES.SAV_PLATFORM.CUSTOMER_REQUESTS.RETURNS,
          path: ':context(returns)/:customerRequestId([0-9]+)?/:claimId([0-9]+)?',
          meta: {
            scrollToSavedPosition: false,
          },
          components: {
            rightPanel: () =>
              dynamicImport(
                import('@sav-platform/pages/ConnectedCustomerRequests.vue'),
              ),
          },
        },
        {
          name: ROUTES.SAV_PLATFORM.CUSTOMER_REQUESTS.CLOSED,
          path: ':context(closed)/:customerRequestId([0-9]+)?/:claimId([0-9]+)?',
          meta: {
            scrollToSavedPosition: false,
          },
          components: {
            rightPanel: () =>
              dynamicImport(
                import('@sav-platform/pages/ConnectedCustomerRequests.vue'),
              ),
          },
        },
        {
          name: ROUTES.SAV_PLATFORM.CUSTOMER_REQUESTS.ALL,
          path: ':context(all)/:customerRequestId([0-9]+)?/:claimId([0-9]+)?',
          meta: {
            scrollToSavedPosition: false,
          },
          components: {
            rightPanel: () =>
              dynamicImport(
                import('@sav-platform/pages/ConnectedCustomerRequests.vue'),
              ),
          },
        },
        {
          name: ROUTES.SAV_PLATFORM.CUSTOMER_REQUESTS.STANDALONE_CONVERSATION,
          path: ':customerRequestId([0-9]+)/:claimId([0-9]+)?',
          meta: {
            scrollToSavedPosition: false,
            ...insertIf(
              config.FF_ENABLE_NEW_SELLER_CUSTOMER_CARE_HELP_REQUEST_OPEN,
              {
                redirectToFrontApps: {
                  name: ROUTES.BO_SELLER.CUSTOMER_CARE.REQUEST,
                },
              },
            ),
          },
          components: {
            rightPanel: () =>
              dynamicImport(
                import(
                  '@customer-requests/Platform/pages/seller/StandaloneConversation/StandaloneConversation.vue'
                ),
              ),
          },
        },
        {
          name: ROUTES.SAV_PLATFORM.CUSTOMER_REQUESTS
            .STANDALONE_CONVERSATION_RESOLUTION,
          path: 'resolution/:resolutionId',
          meta: {
            scrollToSavedPosition: false,
            ...insertIf(
              config.FF_ENABLE_NEW_SELLER_CUSTOMER_CARE_HELP_REQUEST_OPEN,
              {
                redirectToFrontApps: {
                  name: ROUTES.BO_SELLER.CUSTOMER_CARE.REQUEST_RESOLUTION,
                },
              },
            ),
          },
          components: {
            rightPanel: () =>
              dynamicImport(
                import(
                  '@customer-requests/Platform/pages/seller/StandaloneConversation/StandaloneConversation.vue'
                ),
              ),
          },
        },
      ],
    },
    // Customer request - Admin SAV Platform.
    {
      name: ROUTES.SAV_PLATFORM_ADMIN.HOME,
      path: '/bo_admin/customer-request',
      component: () => {
        const isSSRSwitchedOff = config.KILL_CUSTOMER_REQUESTS_SSR_FOR_ADMIN

        return dynamicImport(
          import(
            isSSRSwitchedOff
              ? '@sav-platform/ConnectedSAVPlatformClientOnly'
              : '@sav-platform/ConnectedSAVPlatform'
          ),
        )
      },
      meta: {
        auth: {
          required: true,
          // TODO: Use route names instead
          redirection: ['/bo_merchant/'],
        },
        staff: {
          required: true,
        },
      },
      props: {
        role: SAV_PLATFORM_ROLES.admin,
      },
      redirect: {
        name: ROUTES.SAV_PLATFORM_ADMIN.CUSTOMER_REQUESTS,
      },
      children: [
        ...insertIf(config.FF_ENABLE_RESPONSE_TEMPLATE_PAGE, [
          {
            name: ROUTES.SAV_PLATFORM_ADMIN.RESPONSE_TEMPLATES,
            path: 'response-templates',
            components: {
              rightPanel: () =>
                dynamicImport(import('@sav-templates/ConnectedTemplates')),
            },
          },
        ]),
        ...insertIf(config.FF_ENABLE_STAFF_SERVICE_REQUEST_PAGE, [
          {
            name: ROUTES.SAV_PLATFORM_ADMIN.CUSTOMER_REQUESTS,
            path: `:context(${ALLOWED_CONTEXTS[SAV_PLATFORM_ROLES.admin].join(
              '|',
            )})?/:customerRequestId([0-9]+)?/:claimId([0-9]+)?`,
            meta: {
              scrollToSavedPosition: false,
            },
            components: {
              rightPanel: () =>
                dynamicImport(
                  import('@sav-platform/pages/ConnectedCustomerRequests.vue'),
                ),
            },
          },
        ]),
      ],
    },
    // Customer request - BackCare SAV Platform -> redirected to the new conversation
    {
      name: ROUTES.SAV_PLATFORM_BACKCARE.HOME,
      path: '/bo_admin/backcare/customer-request',
      meta: {
        auth: {
          required: true,
          // TODO: Use route names instead
          redirection: ['/bo_merchant/'],
        },
        canViewServiceRequests: {
          required: true,
        },
      },
      children: [
        {
          name: ROUTES.SAV_PLATFORM_BACKCARE.CUSTOMER_REQUESTS,
          path: `:context(${ALLOWED_CONTEXTS[SAV_PLATFORM_ROLES.backcare].join(
            '|',
          )})?/:customerRequestId([0-9]+)?/:claimId([0-9]+)?`,
          redirect:
            '/customer-care/after-sales/care-folders/:customerRequestId([0-9]+)',
          meta: { disableClientSideNavigation: true },
        },
      ],
    },
    // Customer request - Static files.
    {
      path: '/seller/certificate-to-transport-investigation',
      name: ROUTES.PDF.CERTIFICATE_TO_TRANSPORT_INVESTIGATION,
      meta: { disableClientSideNavigation: true },
    },
  ]
}
