import isEmpty from 'lodash/isEmpty'

import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

function preserveOriginQuery(to, from, next) {
  if (!isEmpty(from.query) && isEmpty(to.query)) {
    next({ ...to, query: from.query })
  } else next()
}

export default ({ country, config }) => {
  const isFrontAppsRouteAuthLogin =
    config.FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_AUTH_LOGIN.includes(country)

  return [
    {
      name: ROUTES.AUTH_OAUTH2.LOGIN,
      path: '/auth/login',
      beforeEnter: preserveOriginQuery,
      component: () => dynamicImport(import('./pages/auth/Login.vue')),
      meta: {
        disableClientSideNavigation: isFrontAppsRouteAuthLogin,
      },
    },
  ]
}
